import React from 'react';
import Layout from '../components/layout';
import HeroImage from '../images/eCommerceHero.jpg';
import Hero from '../components/hero';
import DemoFooter from '../components/demoFooter';

import e1 from '../images/ecommerce1.jpg';
import e2 from '../images/ecommerce2.jpg';
import e3 from '../images/ecommerce3.jpg';
import e4 from '../images/ecommerce4.jpg';
import SectionRight from '../components/sectionRight';
import SectionLeft from '../components/sectionLeft';
import SEO from '../components/seo';
import PartsImage from '../images/parts.png';
import d2 from '../images/distribution2.jpg';

export default function () {
    return (
        <Layout>

            <SectionLeft image={e4} alt={'Mobile phone with PSS website'} title={'Order Automation'} id={'automation'}>
                <p className={'lead'}>
                    The PSS orders module can place orders directly with the distributors, removing the need to place the order directly within the distributor backends. Additionally, PSS will
                    automatically pull in the tracking information from the distributor and upload it to the marketplaces, saving you thousands of dollars on manpower and payroll.
                </p>
            </SectionLeft>

            <SectionLeft image={d2} alt={'Dune Buggy In Sand'} title={'Sync Inventory'} id={'oem-vehicles'}>
                <p className={'lead my-3'}>
                    Our system has the ability to tap into your Lightspeed account and pull price and inventory on a daily basis to send which is then automatically sent to the marketplaces for you.
                    Easily browse our catalog and search for products that you have available in your Lightspeed account that are not yet listed to eBay.
                </p>
            </SectionLeft>


            <DemoFooter/>
        </Layout>
    );
}
